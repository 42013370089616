export default [
	{
		brand: "dejun",
		card: 11,
		name: "Up Dejun",
		order: 1,
		paper: 1,
		perso: true,
	},
	{
		brand: "cadou",
		card: 13,
		name: "Up Cadou",
		order: 2,
		paper: 2,
		perso: true,
	},
	{
		brand: "cadou",
		card: 0,
		name: "Up Cadou STIM",
		order: 3,
		paper: 2,
		perso: false,
	},
	{
		brand: "vacanta",
		card: 12,
		name: "Up Vacanta",
		order: 4,
		paper: 5,
		perso: true,
	},
	{
		brand: "cultura",
		card: 14,
		name: "Up Cultura",
		order: 5,
		paper: 7,
		perso: true,
		permittedValues: [10, 20, 30],
		minTicketValue: 10,
		maxTicketValue: 50,
	},
	{
		brand: "social",
		card: 0,
		name: "Up Social",
		order: 6,
		paper: 4,
		perso: true,
	},
	{
		brand: "gradinita",
		card: 0,
		name: "Up Gradinita",
		order: 7,
		paper: 6,
		perso: false,
	},
	{
		brand: "cresa",
		card: 0,
		name: "Up Cresa",
		order: 8,
		paper: 3,
		perso: true,
	},
];
